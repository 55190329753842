import React from 'react';

import Box from '@mui/material/Box';

const BendableLabsLogo = (props) => {
  const {
    width = {},
  } = props;

  const defaultWidths = { xs: 295, sm: 295, md: 295, lg: 295, xl: 295 };
  const xsWidth = width.xs ? width.xs : defaultWidths.xs;
  const smWidth = width.sm ? width.sm : defaultWidths.sm;
  const mdWidth = width.md ? width.md : defaultWidths.md;
  const lgWidth = width.lg ? width.lg : defaultWidths.lg;
  const xlWidth = width.xl ? width.xl : defaultWidths.xl;

  return (
    <Box
      sx={{
        width: { xs: xsWidth, sm: smWidth, md: mdWidth, lg: lgWidth, xl: xlWidth },
        margin: '0 auto',
      }}
    >
      <a href="https://bendablelabs.com" target="_blank" rel="noopener noreferrer">
        <img src="https://bendable.s3.us-west-1.amazonaws.com/Bendable_Labs_primary_logo_rgb_295.png" alt="Bendable Labs logo" width="100%"/>
      </a>
    </Box>
  );
};

export default BendableLabsLogo;